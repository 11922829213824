document.addEventListener('DOMContentLoaded', () => {
  const closeButtons = document.getElementsByClassName('flash-messages__close_button');

  if (closeButtons.length) {
    closeButtons.forEach((closeButton) => {
      // eslint-disable-next-line func-names
      closeButton.parentElement.addEventListener('click', function () {
        this.classList.add('flash-messages__message_closed');
      });
    });
  }
});
