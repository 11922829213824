import $ from 'jquery';
import 'jquery-serializejson';

$(document).ready(() => {
  const ajaxForms = $('form[data-ajax-action]');

  if (ajaxForms.length) {
    ajaxForms.each((i, form) => {
      // todo add basic client side validation

      $(form).on('submit', (event) => {
        event.preventDefault();

        const $form = $(event.target);
        const formName = $form.attr('name');
        const url = $form.data('ajax-action');

        let method = $form.data('ajax-method');
        if (!method) {
          method = 'POST';
        }

        const data = $form.serializeJSON()[formName];
        const $submitButtons = $form.find('button[type="submit"]');

        if ($submitButtons.length) {
          $submitButtons.each((j, button) => {
            $(button).attr('disabled', true);
          });
        }

        $.ajax(url, {
          method,
          data: JSON.stringify(data),
          contentType: 'application/json',
          dataType: 'json',
          form: $form,
          formName,
          submitButtons: $submitButtons,
          success() {
            const $formFields = this.form.find(`[name^='${this.formName}[']`);

            $formFields.each((j, field) => {
              const $field = $(field);

              $field.parent().removeClass('error');
            });

            const $formWrapper = this.form.closest('.js-form-wrapper');
            $formWrapper.find('.js-form-error').addClass('hide');
            $formWrapper.find('.js-form').addClass('hide');
            $formWrapper.find('.js-form-success').removeClass('hide');
          },
          error(response) {
            const { errors } = response.responseJSON;

            if (typeof errors !== 'undefined' && errors !== null && errors.length) {
              const errorFields = [];

              errors.forEach((error) => {
                let firstError = true;
                if (errorFields.includes(error.field)) {
                  firstError = false;
                }

                errorFields.push(error.field);

                const $input = this.form.find(`[name='${this.formName}[${error.field}]']`);
                const $row = $input.closest('div.form-widget').parent();
                const $errorWrapper = $row.find('div.form-errors');
                let $errorList = $errorWrapper.find('ul');

                if (!$errorList.length) {
                  $errorWrapper.append('<ul></ul>');
                  $errorList = $errorWrapper.find('ul');
                }

                if (firstError) {
                  $errorList.html('');
                }

                $row.addClass('error');
                $errorList.append(`<li>${error.message}</li>`);
              });

              const $formFields = this.form.find(`[name^='${this.formName}[']`);

              $formFields.each((j, field) => {
                const $field = $(field);
                const fieldSerialized = $field.serializeJSON();

                if (this.formName in fieldSerialized) {
                  const fieldName = Object.keys(fieldSerialized[this.formName])[0];

                  if (!errorFields.includes(fieldName)) {
                    $field.parent().removeClass('error');
                  }
                }
              });
            } else {
              const $formWrapper = this.form.closest('.js-form-wrapper');
              const $messageText = $formWrapper.find('.js-form-error');

              $messageText.html('Something went wrong and your message was most likely not sent.<br/>Our developers have been notified and will fix the problem.');

              $formWrapper.find('.js-form').addClass('hide');
              $formWrapper.find('.js-form-success').addClass('hide');
              $formWrapper.find('.js-form-error').removeClass('hide');
            }
          },
          complete() {
            if (this.submitButtons.length) {
              this.submitButtons.each((j, button) => {
                $(button).attr('disabled', false);
              });
            }
          },
        });

        return false;
      });
    });
  }
});
